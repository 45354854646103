import { useState, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';

interface TooltipProps {
	text: string | ReactNode;
	children: ReactNode;
	direction: 'right' | 'bottom' | 'left' | 'top';
	className?: string;
	widthClass?: string;
}

const Tooltip = observer(function Tooltip(props: TooltipProps) {
	const [visible, setVisible] = useState(false);

	return (
		<div
			className={ClassString({
				static: 'relative flex items-center justify-center',
				dynamic: {},
				custom: props.className,
			})}
		>
			<div
				onMouseEnter={() => setVisible(true)}
				onMouseLeave={() => setVisible(false)}
			>
				{props.children}
			</div>
			{visible && (
				<div
					className={ClassString({
						static: 'absolute w-48 p-2 bg-gray-700 text-sm rounded shadow-lg z-10 bg-white dark:bg-gray-550 border border-gray-600 dark:border-gray-500',
						dynamic: {
							'left-full ml-3': props.direction === 'right',
							'top-full mt-3': props.direction === 'bottom',
							'right-full mr-3': props.direction === 'left',
							'bottom-full mb-3': props.direction === 'top',
						},
						custom: props.widthClass,
					})}
				>
					<div className="relative">
						<div
							className={ClassString({
								static: 'absolute transform w-0 h-0',
								dynamic: {
									'-left-4 top-1/2 -translate-y-1/2 border-t-[6px] border-t-transparent border-r-[7px] border-r-gray-600 dark:border-r-gray-500 border-b-[6px] border-b-transparent':
										props.direction === 'right',
									'-top-4 left-1/2 -translate-x-1/2 border-l-[6px] border-l-transparent border-b-[7px] border-b-gray-600 dark:border-b-gray-500 border-r-[6px] border-r-transparent':
										props.direction === 'bottom',
									'-right-4 top-1/2 -translate-y-1/2 border-t-[6px] border-t-transparent border-l-[7px] border-l-gray-600 dark:border-l-gray-500 border-b-[6px] border-b-transparent':
										props.direction === 'left',
									'-bottom-4 left-1/2 -translate-x-1/2 border-l-[6px] border-l-transparent border-t-[7px] border-t-gray-600 dark:border-t-gray-500 border-r-[6px] border-r-transparent':
										props.direction === 'top',
								},
							})}
						></div>
						{props.text}
					</div>
				</div>
			)}
		</div>
	);
});

export default Tooltip;
