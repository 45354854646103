import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	DailyPicks,
	DailyPicksResponseSchema,
} from '@/schemas/daily-picks-schema.ts';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;

const dailyPickUrl = `${baseUrl}/api/platform/picks`;

class DailyPickStore {
	private _dailyPicks: DailyPicks | undefined;

	constructor() {
		makeAutoObservable(this);
	}

	async loadDailyPicks() {
		const response = await httpFetch.GET(dailyPickUrl);

		if (response.ok) {
			const dailyPicksResponse = DailyPicksResponseSchema.parse(
				await response.json()
			);
			const dailyPicks = {
				...dailyPicksResponse,
				date: new Date(dailyPicksResponse.date),
			};

			runInAction(() => {
				this._dailyPicks = dailyPicks;
			});
		}
	}

	get dailyPicks() {
		return this._dailyPicks;
	}
}

const dailyPickStore = new DailyPickStore();
export default dailyPickStore;
