import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import GetPlatform, { Platform } from '@utils/get-platform.ts';
import NavigateToExperience from '@components/navigation/navigate-to-experience/navigate-to-experience.tsx';
import modalStore from '@store/modal-store.ts';
import { useEffect } from 'react';

const GlobalExperienceSearch = observer(function GlobalExperienceSearch() {
	const openGlobalSearch = () => {
		modalStore.open(
			<div className={'w-full p-4'}>
				<NavigateToExperience
					onNavigate={() => {
						modalStore.close();
					}}
					focusOnRender
				/>
			</div>,
			{
				overflow: true,
				className: 'sm:w-[900px] max-w-[90%] absolute top-[20%]',
			}
		);
	};

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			// Start Search
			if (GetPlatform() === Platform.MAC) {
				if (event.getModifierState('Meta') && event.key === 'k') {
					openGlobalSearch();
				}
			} else {
				if (event.getModifierState('Control') && event.key === 'k') {
					openGlobalSearch();
				}
			}
		};
		document.addEventListener('keydown', onKeyDown);

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	return (
		<div
			className={ClassString({
				static: 'p-1 hover:bg-blue-500 rounded-md cursor-pointer flex justify-center items-center select-none text-white',
				dynamic: {
					'w-40': navSidebarStore.minimal,
					'pr-3 ': !navSidebarStore.minimal,
				},
			})}
			onClick={() => {
				openGlobalSearch();
			}}
		>
			<Icon
				icon={IconEnum.SEARCH}
				size={'2.5rem'}
				className={'hover:bg-blue-500 text-white p-1'}
			/>

			<div>
				{!navSidebarStore.minimal && (
					<>{GetPlatform() === Platform.MAC ? '⌘K' : 'Ctrl K'}</>
				)}
			</div>
		</div>
	);
});

export default GlobalExperienceSearch;
