import experienceStore from '@store/experience-store.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import dailyPickStore from '@store/daily-pick-store.ts';

export function OverviewLoader() {
	void platformStatsStore.loadPlatformCCU();
	void dailyPickStore.loadDailyPicks().then(() => {
		if (dailyPickStore.dailyPicks?.place_ids) {
			dailyPickStore.dailyPicks.place_ids.forEach((id) => {
				void experienceStore.getFullExperience(id.toString());
			});
		}
	});

	return 'ok';
}
