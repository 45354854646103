import { observer } from 'mobx-react-lite';
import Button from '@components/core/button/button.tsx';
import { useLoaderData } from 'react-router-dom';
import View from '@components/view/view.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import PlaceTagDetailsBulkAdd from '@views/place-tag-details/view-components/place-tag-details-bulk-add.tsx';
import { PlaceTagDetailsLoaderReturn } from '@views/place-tag-details/place-tag-details-loader.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import {
	CCUDataSegment,
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import PlaceGenreDistribution from '@components/data/place-genre-distribution/place-genre-distribution.tsx';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import GroupBy from '@utils/group-by.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import ViewPanelSizeEnum from '@components/view/view-panel-size-enum.ts';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import AverageTagCCU from '@components/data/average-tag-ccu/average-tag-ccu.tsx';
import PlaceTagDetailsAdd from '@views/place-tag-details/view-components/place-tag-details-add.tsx';
import ExperienceTable from '@components/tables/experience-table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import ActionsMenu from '@components/core/actions-menu/actions-menu.tsx';
import copyService from '@services/copy-service.ts';
import ModalBase from '@components/modal/modal-base.tsx';
import { PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { useEffect, useState } from 'react';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import creatorStore from '@store/creator-store.ts';
import ViewExpansionPanel from '@components/view/view-expansion-panel.tsx';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import modalStore from '@store/modal-store.ts';

const robloxPlaceUrl = 'https://www.roblox.com/games/';

const removeExperience = async (
	populatedPlaceTag: PopulatedPlaceTag,
	place: FullExperience
) => {
	await placeTagStore.deletePlaceIdFromTag(
		populatedPlaceTag.id,
		place.place_id
	);

	toastStore.emit(
		`Successfully removed experience from tag`,
		ToastType.CONFIRM
	);
};

const PlaceTagDetails = observer(function PlaceTagDetails() {
	const { placeTagId } = useLoaderData() as PlaceTagDetailsLoaderReturn;
	const populatedPlaceTag = placeTagStore.populatedPlaceTag(+placeTagId);

	const [ccuChartState, setCCUChartState] = useState(CCUDataSegment.ACTIVE);

	const top5Places = [...populatedPlaceTag.places]
		.sort((a, b) => b.players_online - a.players_online)
		.slice(0, 5);

	const addPlaceToTag = (id: number) => {
		const placeList = populatedPlaceTag?.places.map(
			(place) => place.place_id
		);
		placeList?.push(id);
		void placeTagStore.updatePlaceTag(populatedPlaceTag.id, {
			...populatedPlaceTag,
			place_ids: placeList,
		});
	};

	const bulkAddPlacesToTag = (placeIds: string[]) => {
		const placeList = populatedPlaceTag?.places.map(
			(place) => place.place_id
		);
		placeList?.push(...placeIds.map((placeId) => +placeId));
		void placeTagStore.updatePlaceTag(populatedPlaceTag.id, {
			...populatedPlaceTag,
			place_ids: placeList,
		});
	};

	const placesGroupedByCreator = GroupBy(
		populatedPlaceTag.places,
		(place: FullExperience) => place.creator_id
	);

	const topCreator = Object.entries(
		Object.fromEntries(placesGroupedByCreator.entries())
	)
		.map((entry) => {
			return {
				name:
					creatorStore.getCreatorById(entry[1][0].creator_id)?.name ??
					'Unknown Creator',
				places: entry[1],
				totalPlayers: entry[1].reduce(
					(a, b) => a + b.players_online || 0,
					0
				),
			};
		})
		.sort((a, b) => b.totalPlayers - a.totalPlayers)[0];

	useEffect(() => {
		postHogService.capture({
			type: PostHogEventType.ViewTag,
			tag: populatedPlaceTag.name,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: populatedPlaceTag?.name ?? '',
				icon: IconEnum.TAG,
				breadCrumbs: [{ title: 'Tags', route: '/place-tags' }],
			}}
			headerChildren={
				populatedPlaceTag && (
					<div
						className={
							'w-full flex items-center justify-end flex-wrap'
						}
					>
						<Button
							title={'Add'}
							icon={{
								icon: IconEnum.ADD,
								placement: 'right',
							}}
							className={'mx-4'}
							onClick={() =>
								modalStore.open(
									<PlaceTagDetailsAdd
										tagName={populatedPlaceTag?.name ?? ''}
										submit={addPlaceToTag}
									/>,
									{ overflow: true }
								)
							}
						/>

						<div className={'border-l pl-4 dark:border-gray-400'}>
							<Button
								title={'Bulk Add'}
								icon={{
									icon: IconEnum.BULK_ADD,
									placement: 'right',
								}}
								onClick={() =>
									modalStore.open(
										<PlaceTagDetailsBulkAdd
											tagName={
												populatedPlaceTag?.name ?? ''
											}
											submit={bulkAddPlacesToTag}
										/>
									)
								}
							/>
						</div>
					</div>
				)
			}
			className={'place-tag-details'}
		>
			<ViewExpansionPanel
				title={`Experiences (${populatedPlaceTag.places.length})`}
				startExpanded
			>
				<ExperienceTable
					experiences={populatedPlaceTag.places}
					contextMenu={{
						cellRenderer: (params: CustomCellRendererProps) => {
							const place = params.node.data as FullExperience;
							return (
								<ActionsMenu
									actions={[
										{
											title: 'View on roblox.com',
											onClick: () => {
												window.open(
													`${robloxPlaceUrl}${place.place_id}`
												);
											},
										},
										{
											title: 'Remove',
											onClick: () => {
												modalStore.open(
													<ModalBase
														title={`Remove experience from tag`}
														content={{
															description: `Are you sure you want to remove "${place.name}" from the tag "${populatedPlaceTag.name}"?`,
														}}
														onConfirm={async () => {
															await removeExperience(
																populatedPlaceTag,
																place
															);
														}}
														options={{
															confirmButtonTitle:
																'Remove',
														}}
													/>
												);
											},
										},
										{
											title: 'Copy Experience ID',
											onClick: () => {
												copyService.experienceId(place);
											},
										},
										{
											title: 'Copy Universe ID',
											onClick: () => {
												copyService.experienceUniverseId(
													place
												);
											},
										},
									]}
									width={'13rem'}
								/>
							);
						},
						minWidth: 70,
						maxWidth: 70,
						resizable: false,
						sortable: false,
						cellStyle: {
							display: 'flex',
							alignItems: 'center',
						},
						context: {
							priority: 1,
						},
					}}
					fillParent
					includeRanking={false}
				/>
			</ViewExpansionPanel>

			<ViewPanel>
				<LightdashDashboards
					dashboards={[
						{
							type: DashboardType.EXPERIENCE_INSIGHT_BY_TAG,
							tagName: populatedPlaceTag?.name,
						},
					]}
				/>
			</ViewPanel>

			{!!populatedPlaceTag.places.length && (
				<>
					<ViewPanel options={{ size: ViewPanelSizeEnum.HALF }}>
						<PlaceGenreDistribution
							places={[...populatedPlaceTag.places]}
						/>
					</ViewPanel>

					<ViewPanel
						options={{
							size: ViewPanelSizeEnum.HALF,
							centerContent: true,
						}}
					>
						<QuickData
							data={new Intl.NumberFormat(
								navigator.language
							).format(
								populatedPlaceTag.places.reduce(
									(a, b) => a + b.players_online || 0,
									0
								)
							)}
							title={'Total Players Online'}
						/>

						<QuickData
							data={new Intl.NumberFormat(
								navigator.language
							).format(
								populatedPlaceTag.places.reduce(
									(a, b) => a + b.total_visits || 0,
									0
								)
							)}
							title={'Total Visits'}
						/>

						<QuickData
							data={
								new Set(
									populatedPlaceTag.places.map(
										(place) => place.creator_id
									)
								).size
							}
							title={'Creators'}
						/>

						<QuickData
							data={topCreator?.name}
							title={'Top Creator (Players Online)'}
						/>
					</ViewPanel>

					<ViewTabPanel
						tabs={[
							{
								key: CCUDataSegment.ACTIVE,
								title: 'Active',
								tooltip:
									'Only include experiences with active players at the specific timestamp.',
							},
							{
								key: CCUDataSegment.TOP_FIVE,
								title: 'Top Five',
								tooltip: (
									<div>
										{
											'Only include the top 5 experiences of the tag in terms of current player count.'
										}
										<ul className="pl-5 mt-1 list-disc">
											{[...populatedPlaceTag.places]
												.sort(
													(a, b) =>
														b.players_online -
														a.players_online
												)
												.slice(0, 5)
												.map((exp) => {
													return (
														<li key={exp.place_id}>
															{exp.canonical_name}
														</li>
													);
												})}
										</ul>
									</div>
								),
							},
							{
								key: CCUDataSegment.ALL,
								title: 'All',
								tooltip:
									'Include all experiences that was created at or after each specific timestamp.',
							},
						]}
						onTabChange={(tab) => {
							setCCUChartState(tab);
						}}
					>
						<AverageTagCCU
							tag={populatedPlaceTag}
							state={ccuChartState}
						/>
					</ViewTabPanel>
				</>
			)}

			{!!top5Places.length && (
				<ViewPanel>
					<PlaceStats
						places={top5Places}
						statType={PlaceStatType.CCUS}
						title={'Concurrent Users (Current Top 5)'}
						options={{
							showLegend: true,
							useLineGraph: true,
							thickerLines: true,
							selectedRange: 7,
							defaultGranularity: PlaceStatGranularity.WEEK,
						}}
					/>
				</ViewPanel>
			)}
		</View>
	);
});

export default PlaceTagDetails;
