import { observer } from 'mobx-react-lite';
import ModalBase from '@components/modal/modal-base.tsx';
import InputText from '@components/core/input-text/input-text.tsx';
import { ChangeEvent, useState } from 'react';

interface PlaceTagsCreateNewModalProps {
	onSubmit: (tagName: string) => unknown;
}

const PlaceTagsCreateNewModal = observer(function PlaceTagsCreateNewModal(
	props: PlaceTagsCreateNewModalProps
) {
	const [tagName, setTagName] = useState('');

	const handleTagNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setTagName(event.target.value);
	};

	return (
		<ModalBase
			title={`New Tag`}
			content={{
				children: (
					<InputText
						label={'Tag Name'}
						value={tagName}
						onInput={handleTagNameChange}
						options={{ noMargin: true }}
					/>
				),
			}}
			onConfirm={() => {
				props.onSubmit(tagName);
			}}
			options={{
				confirmButtonTitle: 'Create',
				confirmButtonDisabled: !tagName.length,
			}}
		/>
	);
});

export default PlaceTagsCreateNewModal;
