import { z } from 'zod';

export interface DailyPicksResponse {
	place_ids: number[];
	date: string; //Date string
}

export interface DailyPicks {
	place_ids: number[];
	date: Date;
}

export const DailyPicksResponseSchema: z.ZodType<DailyPicksResponse> = z.object(
	{
		place_ids: z.array(z.number()),
		date: z.string(),
	}
);
