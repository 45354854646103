import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import ClassString from '@utils/class-string.ts';
import Tooltip from '@components/core/tooltip/tooltip.tsx';

interface ViewTabPanelProps<T> {
	children?: React.ReactNode;
	title?: string;
	loading?: boolean;
	className?: string;
	tabs: {
		key: T;
		title: string;
		children?: React.ReactNode;
		tooltip?: React.ReactNode;
	}[];
	onTabChange?: (tab: T) => unknown;
}

const ViewTabPanel = observer(function ViewTabPanel<T>(
	props: ViewTabPanelProps<T>
) {
	const [selectedTab, setSelectedTab] = useState<T | undefined>(
		props.tabs?.length ? props.tabs[0].key : undefined
	);

	const onTabChange = (tab: T) => {
		setSelectedTab(tab);

		if (props.onTabChange) {
			props.onTabChange(tab);
		}
	};

	return (
		<div
			className={ClassString({
				static: 'p-4 rounded-lg shadow-md w-full bg-white dark:bg-gray-550 outline outline-1 outline-gray-300 dark:outline-gray-500 relative mt-10',
				dynamic: {},
				custom: props.className,
			})}
		>
			{!props.loading && (
				<div
					className={
						'absolute -top-10 left-5 flex items-center gap-4'
					}
				>
					{props.tabs.map((tab, index) => {
						return (
							<div
								className={ClassString({
									static: 'bg-white dark:bg-gray-550 h-10 flex justify-center items-center rounded-t-md text-lg border border-gray-300 dark:border-gray-500 cursor-pointer select-none',
									dynamic: {
										'border-b-0': tab.key === selectedTab,
										'p-4': !tab.tooltip,
									},
								})}
								key={`tab-${index}`}
								onClick={() => {
									if (tab.title !== selectedTab) {
										onTabChange(tab.key);
									}
								}}
							>
								{!!tab.tooltip && (
									<Tooltip
										text={tab.tooltip}
										direction={'right'}
										className={'w-full h-full p-4'}
										widthClass={'w-72'}
									>
										{tab.title}
									</Tooltip>
								)}

								{!tab.tooltip && <>{tab.title}</>}
							</div>
						);
					})}
				</div>
			)}

			{!props.loading && !!props.tabs?.length && (
				<>
					{props.tabs.map((tab, index) => {
						return (
							<div
								key={`tab-content-${index}`}
								className={ClassString({
									dynamic: {
										hidden: selectedTab !== tab.key,
									},
								})}
							>
								{tab.children}
							</div>
						);
					})}
				</>
			)}

			{!props.loading && !!props.children && (
				<>
					{!!props.title && (
						<div
							className={
								'text-2xl mb-4 flex justify-center items-center'
							}
						>
							{props.title}
						</div>
					)}
					{props.children}
				</>
			)}

			{props.loading && (
				<div className={'flex justify-center items-center'}>
					<LoadingIndicator />
				</div>
			)}
		</div>
	);
});

export default ViewTabPanel;
