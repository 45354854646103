import experienceStore from '@store/experience-store.ts';
import thumbnailStore from '@store/thumbnail-store.ts';

export interface PlaceDetailsLoaderReturn {
	placeId: string;
}

export async function PlaceDetailsLoader(placeId: string) {
	if (experienceStore.getFullExperienceById(+placeId)) {
		void experienceStore.getFullExperience(placeId);
		return { placeId };
	}

	const res = await experienceStore.getFullExperience(placeId);

	if (!res.ok) {
		if (!experienceStore.getFullExperienceById(+placeId)) {
			throw new Response(null, { status: res.status });
		}
	}

	void thumbnailStore.loadAllThumbnails(+placeId);

	return { placeId };
}
