import placeTagStore from '@store/place-tag-store.ts';

export interface PlaceTagDetailsLoaderReturn {
	placeTagId: string;
}

export async function PlaceTagDetailsLoader(
	placeTagId: string
): Promise<PlaceTagDetailsLoaderReturn | null> {
	if (placeTagStore.populatedPlaceTag(+placeTagId)) {
		void placeTagStore.getPopulatedPlaceTag(+placeTagId);
		return { placeTagId };
	} else {
		await placeTagStore.getPopulatedPlaceTag(+placeTagId);
	}

	if (!placeTagStore.populatedPlaceTag(+placeTagId)) {
		throw new Response('Not Found', { status: 404 });
	}

	return { placeTagId };
}
