import { observer } from 'mobx-react-lite';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import placeTagStore from '@store/place-tag-store.ts';
import { Link } from 'react-router-dom';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import {
	FirstDataRenderedEvent,
	PaginationChangedEvent,
	SortChangedEvent,
	ValueFormatterParams,
} from 'ag-grid-community';
import ExperienceCreator from '@components/data/experience-creator/experience-creator.tsx';
import thumbnailStore from '@store/thumbnail-store.ts';
import { useRef } from 'react';

interface PlacesTableProps {
	experiences: Experience[];
	contextMenu?: CustomColDef<Experience>;
	fillParent?: boolean;
	includeRanking?: boolean;
	includeCreator?: boolean;
}

const numberFormatter = new Intl.NumberFormat(navigator.language);

const columnDefs = ({
	includeRanking = true,
	includeCreator = true,
}): CustomColDef<Experience>[] => {
	return [
		{
			minWidth: 55,
			maxWidth: 55,
			cellRenderer: (params: CustomCellRendererProps) => {
				return (
					<div
						className={
							'w-full h-full flex justify-center items-center'
						}
					>
						<PlaceThumbnail
							place={params.node.data as Experience}
							size={PlaceThumbnailSize.VERY_SMALL}
							className={'rounded-md'}
						/>
					</div>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingRight: '0',
			},
			resizable: false,
			sortable: false,
			context: {
				priority: 1,
			},
		},
		{
			headerName: 'Name',
			field: 'name',
			resizable: false,
			cellRenderer: (params: CustomCellRendererProps) => {
				const place = params.node.data as Experience;
				return (
					<NavigationButton
						path={`/experiences/${place.place_id}`}
						direction={NavigationButtonEnum.FORWARD}
						title={place.name}
					/>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '25px',
				paddingRight: '0',
			},
			minWidth: 300,
			context: {
				priority: 1,
			},
			flex: 1,
		},
		...(includeRanking
			? ([
					{
						headerName: '#',
						headerClass: 'ag-right-aligned-header',
						minWidth: 60,
						maxWidth: 60,
						cellRenderer: (params: CustomCellRendererProps) => {
							const place = params.node.data as Experience;
							return (
								<div className="flex justify-end items-center text-right w-full">
									{place.rank ? `#${place.rank}` : '?'}
								</div>
							);
						},
						cellStyle: {
							display: 'flex',
							alignItems: 'center',
						},
						resizable: false,
						sortable: false,
					},
				] as CustomColDef<Experience>[])
			: ([] as CustomColDef<Experience>[])),
		{
			headerName: 'Players Online',
			field: 'players_online',
			sort: 'desc',
			resizable: false,
			valueFormatter: (
				params: ValueFormatterParams<Experience, number>
			) => {
				return numberFormatter.format(params.value ?? 0);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
			minWidth: 130,
			maxWidth: 150,
			context: {
				priority: 2,
			},
		},
		...(includeCreator
			? ([
					{
						headerName: 'Creator',
						field: 'creator_id',
						cellRenderer: (params: CustomCellRendererProps) => {
							const experience = params.node.data as Experience;
							return (
								<NavigationButton
									path={`/creators/${experience.creator_id}`}
								>
									<ExperienceCreator
										creatorId={experience.creator_id}
									/>
								</NavigationButton>
							);
						},
						resizable: false,
						cellStyle: {
							display: 'flex',
							alignItems: 'center',
							paddingLeft: '25px',
							paddingRight: '0',
						},
						minWidth: 200,
						context: {
							priority: 4,
						},
						sortable: false,
					},
				] as CustomColDef<Experience>[])
			: ([] as CustomColDef<Experience>[])),
		{
			headerName: 'Tags',
			cellRenderer: (params: CustomCellRendererProps) => {
				const place = params.node.data as Experience;
				const tags = placeTagStore.getPlaceTagsByPlaceId(
					place.place_id
				);
				return tags.map((tag, index) => {
					return (
						<div className={'w-full inline truncate'} key={tag.id}>
							<Link
								to={`/tags/${tag.id}`}
								title={tag.name}
								className={
									'h-full inline-flex items-center hover:underline'
								}
							>
								{tag.name}
							</Link>
							{index < tags.length - 1 && (
								<span
									className={
										'mr-1 h-full inline-flex items-center'
									}
								>
									{', '}
								</span>
							)}
						</div>
					);
				});
			},
			resizable: false,
			sortable: false,
			minWidth: 200,
			context: {
				priority: 3,
			},
		},
	];
};

const ExperienceTable = observer(function PlacesTable(props: PlacesTableProps) {
	const initialized = useRef(false);
	const defs = [
		...columnDefs({
			includeRanking: props.includeRanking,
			includeCreator: props.includeCreator,
		}),
	];

	if (props.contextMenu) {
		defs.push(props.contextMenu);
	}

	const loadThumbnails = (
		params:
			| PaginationChangedEvent
			| FirstDataRenderedEvent
			| SortChangedEvent
	) => {
		const pageSize = params.api.paginationGetPageSize();
		const currentPage = params.api.paginationGetCurrentPage();
		const filteredData: Experience[] = [];
		params.api.forEachNodeAfterFilterAndSort((n) => {
			filteredData.push(n.data as Experience);
		});
		const startIndex = currentPage * pageSize;
		const endIndex = startIndex + pageSize;

		const pageData = filteredData.slice(startIndex, endIndex);
		void thumbnailStore.loadThumbnails(pageData);
	};

	const onPaginationChanged = (params: PaginationChangedEvent) => {
		if (params.newPage || (params.newData && initialized.current)) {
			loadThumbnails(params);
		}
	};

	const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
		setTimeout(() => {
			// Let potential initial searching apply grabbing thumbnails
			loadThumbnails(params);
			// Avoid double call from the onPaginationChanged event
			initialized.current = true;
		}, 500);
	};

	const onSortChanged = (params: SortChangedEvent) => {
		loadThumbnails(params);
	};

	return (
		<TGSTable
			columnDefs={defs}
			rowData={props.experiences}
			tableName={'place-table'}
			rowHeight={50}
			fillParent={props.fillParent}
			onPaginationChanged={onPaginationChanged}
			onFirstDataRendered={onFirstDataRendered}
			onSortChanged={onSortChanged}
		/>
	);
});

export default ExperienceTable;
