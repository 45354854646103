import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ExperienceTable from '@components/tables/experience-table.tsx';
import { useEffect, useState } from 'react';
import experienceStore from '@store/experience-store.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import ExperienceSearch from '@views/places/view-components/experience-search.tsx';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import { CustomCellRendererProps } from 'ag-grid-react';
import ActionsMenu from '@components/core/actions-menu/actions-menu.tsx';
import actionsMenuService from '@services/actions-menu-service.tsx';
import copyService from '@services/copy-service.ts';
import { useNavigate } from 'react-router-dom';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import httpFetch from '@services/http-fetch-service.ts';
import DownloadFileFromResponse from '@utils/download-file-from-response.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import useHashQueryParams from '@hooks/use-hash-query-params.tsx';
import searchService from '@services/search-service.ts';

const robloxPlaceUrl = 'https://www.roblox.com/games/';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;

const Places = observer(function Places() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useHashQueryParams();
	const [experiences, setExperiences] = useState<SlimExperience[]>([]);
	const [loading, setLoading] = useState(true);
	const initialized = experienceStore.initialized;

	useEffect(() => {
		if (initialized && loading) {
			let filteredExperiences = experienceStore.slimExperiences;
			if (searchParams.search && searchParams.search.length >= 3) {
				filteredExperiences = filteredExperiences.filter((exp) =>
					searchService.experience(exp, searchParams.search)
				);
			}

			setExperiences(filteredExperiences);
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialized, loading]);

	return (
		<View
			viewInfo={{ title: 'Experiences', icon: IconEnum.PLACE }}
			fullHeight={loading}
			loading={loading}
			headerChildren={
				<ExperienceSearch
					onSearch={(searchResult, searchTerm) => {
						setExperiences(searchResult);
						setSearchParams({
							...searchParams,
							search: searchTerm,
						});
					}}
					searchParams={searchParams.search}
				/>
			}
		>
			<ViewPanel options={{ noPadding: true, noOutline: true }}>
				<ExperienceTable
					experiences={experiences}
					contextMenu={{
						cellRenderer: (params: CustomCellRendererProps) => {
							const place = params.node.data as SlimExperience;
							return (
								<ActionsMenu
									actions={[
										{
											title: 'View on roblox.com',
											onClick: () => {
												window.open(
													`${robloxPlaceUrl}${place.place_id}`
												);
											},
										},
										{
											title: 'Compare to...',
											onClick: () => {
												actionsMenuService.close();
												navigate(
													`/compare/experiences#exp=${place.place_id}`
												);
											},
										},
										{
											title: 'Copy Experience ID',
											onClick: () => {
												copyService.experienceId(place);
											},
										},
										{
											title: 'Copy Universe ID',
											onClick: () => {
												copyService.experienceUniverseId(
													place
												);
											},
										},
										{
											title: 'Download Badge Stats',
											onClick: async () => {
												postHogService.capture({
													type: PostHogEventType.DownloadBadgeStats,
													experience:
														place.canonical_name,
												});

												try {
													const res =
														await httpFetch.GET(
															`${baseUrl}/api/badges/${place.place_id}`,
															true,
															false,
															{
																Accept: 'text/csv',
															}
														);
													await DownloadFileFromResponse(
														res,
														`${place.canonical_name.toLowerCase().replaceAll(' ', '-')}-${place.place_id}-badge-data`,
														'csv'
													);
												} catch (_) {
													toastStore.emit(
														'Could not download badge stats',
														ToastType.ERROR
													);
												}
											},
										},
									]}
									width={'14rem'}
								/>
							);
						},
						minWidth: 70,
						maxWidth: 70,
						resizable: false,
						sortable: false,
						cellStyle: {
							display: 'flex',
							alignItems: 'center',
						},
						context: {
							priority: 1,
						},
					}}
				/>
			</ViewPanel>
		</View>
	);
});

export default Places;
