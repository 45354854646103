import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import experienceStore from '@store/experience-store.ts';
import { useState } from 'react';
import { Experience } from '@/schemas/experience-schema.ts';
import PlaceStats from '@components/data/place-stats/place-stats.tsx';
import {
	PlaceStatGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Button from '@components/core/button/button.tsx';
import CopyToClipboard from '@utils/copy-to-clipboard.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import MultipleExperienceSelector from '@components/core/multiple-experience-selector/multiple-experience-selector.tsx';
import ChartColors from '@components/core/chart/chart-colors.ts';
import ExperienceComparisonTwoExperiences from '@views/experience-comparison/view-components/experience-comparison-two-experiences.tsx';
import ExperienceComparisonMultipleExperiencesTable from '@views/experience-comparison/view-components/experience-comparison-multiple-experiences-table.tsx';

const ExperienceComparison = observer(function ExperienceComparison() {
	const [experiences, setExperiences] = useState<Experience[]>([]);

	const loading = !experienceStore.slimExperiences.length;

	const copyLink = () => {
		CopyToClipboard(window.location.href);
		toastStore.emit(
			'Comparison link has been copied to your clipboard.',
			ToastType.INFO
		);
	};

	const colors = [
		ChartColors()[0],
		ChartColors()[1],
		ChartColors()[2],
		ChartColors()[3],
		ChartColors()[4],
		ChartColors()[5],
	];

	return (
		<View
			viewInfo={{
				title: 'Compare Experiences',
				icon: IconEnum.COMPARE,
				breadCrumbs: [{ title: 'Compare' }],
			}}
			hideHeader
		>
			<ViewPanel loading={loading}>
				{!loading && (
					<div className={'w-full flex flex-wrap justify-evenly'}>
						<div
							className={
								'p-6 flex flex-col justify-center items-center max-w-96 lg:h-72 m-2'
							}
						>
							<div>
								Select up to six experiences to display
								comparative stats and dashboard links.
							</div>

							<div className={'mt-2'}>
								You can also bookmark a comparison to quickly
								return to it or share the comparison with
								someone else.
							</div>

							<div className={'mt-6'}>
								<Button
									title={'Share'}
									icon={{
										icon: IconEnum.SHARE,
										placement: 'left',
									}}
									onClick={copyLink}
								/>
							</div>
						</div>

						<MultipleExperienceSelector
							experiences={experiences}
							onAddExperience={(exp) => {
								setExperiences([...experiences, ...exp]);
							}}
							onRemoveExperience={(experience) => {
								setExperiences(
									experiences.filter(
										(exp) =>
											exp.place_id !== experience.place_id
									)
								);
							}}
							colors={colors}
							fullSizedPreviews={2}
							className={'w-full sm:w-auto sm:min-w-[420px]'}
						/>
					</div>
				)}
			</ViewPanel>

			{experiences.length == 2 && (
				<>
					<ViewPanel>
						<LightdashDashboards
							dashboards={[
								{
									type: DashboardType.COMPARE_EXPERIENCES,
									experience1: experiences[0],
									experience2: experiences[1],
								},
							]}
						/>
					</ViewPanel>

					<ViewPanel>
						<ExperienceComparisonTwoExperiences
							experience1={experiences[0]}
							experience2={experiences[1]}
						/>
					</ViewPanel>
				</>
			)}

			{experiences.length > 2 && (
				<ViewPanel options={{ noPadding: true, noOutline: true }}>
					<ExperienceComparisonMultipleExperiencesTable
						experiences={experiences}
						colors={colors}
					/>
				</ViewPanel>
			)}

			{experiences.length >= 2 && (
				<>
					<ViewPanel>
						<PlaceStats
							places={experiences}
							statType={PlaceStatType.CCUS}
							title={'Concurrent Users'}
							options={{
								showLegend: true,
								defaultGranularity: PlaceStatGranularity.WEEK,
								thickerLines: experiences.length == 2,
								selectedRange: 7,
							}}
						/>
					</ViewPanel>

					<ViewPanel>
						<PlaceStats
							places={experiences}
							statType={PlaceStatType.VISITS}
							title={'Visits'}
							options={{
								showLegend: true,
								defaultGranularity: PlaceStatGranularity.WEEK,
								thickerLines: experiences.length == 2,
								selectedRange: 7,
							}}
						/>
					</ViewPanel>

					<ViewPanel>
						<PlaceStats
							places={experiences}
							statType={PlaceStatType.RATING}
							title={'Like Rate'}
							options={{
								showLegend: true,
								yPercentage: true,
								defaultGranularity: PlaceStatGranularity.WEEK,
								thickerLines: experiences.length == 2,
								selectedRange: 7,
							}}
						/>
					</ViewPanel>
				</>
			)}

			{/*)}*/}
		</View>
	);
});

export default ExperienceComparison;
