import { observer } from 'mobx-react-lite';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
	ColDef,
	FirstDataRenderedEvent,
	GridSizeChangedEvent,
	PaginationChangedEvent,
	SortChangedEvent,
} from 'ag-grid-community';
import './table.scss';
import themeStore from '@store/theme-store.ts';
import ClassString from '@utils/class-string.ts';

interface NewTableProps<TData> {
	columnDefs: CustomColDef<TData>[];
	rowData: TData[];
	tableName: string;
	rowHeight?: number;
	fillParent?: boolean;
	disablePagination?: boolean;
	suppressCellFocus?: boolean;
	onPaginationChanged?: (params: PaginationChangedEvent) => unknown;
	onFirstDataRendered?: (params: FirstDataRenderedEvent) => unknown;
	onSortChanged?: (params: SortChangedEvent) => unknown;
	isHidden?: boolean;
}

export interface CustomColDef<TData> extends ColDef<TData> {
	context?: {
		priority?: number;
	};
}

const getPageSize = (key: string): number => {
	const storedPageSize = localStorage.getItem(key);
	return storedPageSize ? +storedPageSize : 20;
};

const TGSTable = observer(function TGSTable<TData>(
	props: NewTableProps<TData>
) {
	const pageSizeLocaleStorageKey = `${props.tableName}-table-size`;

	const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
		if (props.onFirstDataRendered) {
			props.onFirstDataRendered(params);
		}
	};

	const onPaginationChanged = (params: PaginationChangedEvent) => {
		if (props.onPaginationChanged) {
			props.onPaginationChanged(params);
		}

		if (params.newPageSize) {
			localStorage.setItem(
				pageSizeLocaleStorageKey,
				params.api.paginationGetPageSize().toString()
			);
		}
	};

	const onSortChanged = (params: SortChangedEvent) => {
		if (props.onSortChanged) {
			props.onSortChanged(params);
		}
	};

	const onGridSizeChanged = (params: GridSizeChangedEvent) => {
		// get the current grids width
		const gridWidth = params.clientWidth;

		// keep track of which columns to hide/show
		const columnsToShow = [];
		const columnsToHide = [];

		// iterate over all columns (visible or not) and work out
		// now many columns can fit (based on their minWidth)
		let totalColsWidth = 0;
		const allColumns = params.api.getColumns();

		if (allColumns) {
			allColumns.sort((a, b) => {
				const colDefA = a.getColDef() as CustomColDef<TData>;
				const colDefB = b.getColDef() as CustomColDef<TData>;
				const priorityA =
					colDefA.context?.priority ?? Number.MAX_SAFE_INTEGER;
				const priorityB =
					colDefB.context?.priority ?? Number.MAX_SAFE_INTEGER;
				return priorityA - priorityB;
			});
		}

		if (allColumns && allColumns.length > 0) {
			for (const column of allColumns) {
				totalColsWidth += column.getMinWidth();
				if (totalColsWidth > gridWidth) {
					columnsToHide.push(column.getColId());
				} else {
					columnsToShow.push(column.getColId());
				}
			}
		}

		// show/hide columns based on current grid width
		params.api.setColumnsVisible(columnsToShow, true);
		params.api.setColumnsVisible(columnsToHide, false);
	};

	const pageSize = getPageSize(pageSizeLocaleStorageKey);

	return (
		<div
			className={
				themeStore.lightTheme
					? 'ag-theme-quartz'
					: 'ag-theme-quartz-dark'
			}
		>
			<AgGridReact
				columnDefs={props.columnDefs}
				rowData={props.rowData}
				domLayout={'autoHeight'}
				paginationPageSizeSelector={true}
				pagination={!props.disablePagination}
				suppressCellFocus={props.suppressCellFocus ?? true}
				stopEditingWhenCellsLoseFocus={true}
				suppressDragLeaveHidesColumns={true}
				onGridSizeChanged={onGridSizeChanged}
				onFirstDataRendered={onFirstDataRendered}
				paginationPageSize={pageSize}
				onPaginationChanged={onPaginationChanged}
				onSortChanged={onSortChanged}
				rowHeight={props.rowHeight}
				className={ClassString({
					dynamic: {
						'[&>.ag-root-wrapper]:border-0 [&>.ag-root-wrapper]:rounded-none':
							props.fillParent,
						'[&_.ag-center-cols-viewport]:!min-h-0':
							props.rowData.length > 0,
					},
				})}
			/>
		</div>
	);
});

export default TGSTable;
