import { observer } from 'mobx-react-lite';
import ExpansionInput from '@components/core/expansion-input/expansion-input.tsx';
import { ChangeEvent, RefObject } from 'react';
import { Experience, SlimExperience } from '@/schemas/experience-schema.ts';
import experienceStore from '@store/experience-store.ts';
import RobloxUrlToPlaceId from '@utils/roblox-url-to-place-id.ts';
import PlaceThumbnail from '@components/data/place-thumbnail/place-thumbnail.tsx';
import { PlaceThumbnailSize } from '@components/data/place-thumbnail/place-thumbnail-constants.ts';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import thumbnailStore from '@store/thumbnail-store.ts';

interface PlaceExpansionInputProps {
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	place: Experience | undefined;
	setPlace: (place: Experience | undefined) => unknown;
	className?: string;
	elementToFocus?: RefObject<HTMLElement>;
	width?: string;
	fullWidth?: boolean;
	label?: string;
}

const inputLimit = 9;

const PlaceExpansionInput = observer(function PlaceExpansionInput(
	props: PlaceExpansionInputProps
) {
	const searchFilter = (place: Experience): boolean => {
		return (
			place.name.toLowerCase().includes(props.searchTerm.toLowerCase()) ||
			place.place_id.toString().toLowerCase() ===
				props.searchTerm.toLowerCase()
		);
	};

	const matchingPlaces =
		props.searchTerm.length > 2
			? experienceStore.slimExperiences.filter((place) =>
					searchFilter(place)
				)
			: [];

	void thumbnailStore.loadThumbnails(matchingPlaces.slice(0, inputLimit));

	const onInput = (event: ChangeEvent<HTMLInputElement>) => {
		const extractedPlaceId = RobloxUrlToPlaceId(event.target.value);
		if (extractedPlaceId) {
			props.setSearchTerm(extractedPlaceId);
			return;
		}
		props.setSearchTerm(event.target.value);
	};

	const onBlur = () => {
		if (props.searchTerm && matchingPlaces.length === 1) {
			props.setSearchTerm(matchingPlaces[0].name);
			setCurrentPlace(matchingPlaces[0]);
		} else {
			setCurrentPlace(undefined);
		}
	};

	const onFocus = () => {
		setCurrentPlace(undefined);
	};

	const setCurrentPlace = (place: SlimExperience | undefined) => {
		props.setPlace(place);
	};

	return (
		<div
			className={ClassString({
				static: 'relative w-fit',
				dynamic: { 'w-full': props.fullWidth },
				custom: props.className,
			})}
		>
			{!!props.place && (
				<div
					className={
						'absolute h-7 w-7 mr-2 top-1 left-1 z-in-front-3'
					}
				>
					<PlaceThumbnail
						place={props.place}
						size={PlaceThumbnailSize.VERY_SMALL}
						className={'h-7 w-7 rounded-sm'}
					/>
				</div>
			)}

			{!!props.searchTerm && (
				<Icon
					icon={IconEnum.CLEAR}
					size={'1.75rem'}
					onClick={() => {
						props.setSearchTerm('');
						setCurrentPlace(undefined);
					}}
					className={
						'absolute top-1 right-1.5 p-0.5 rounded-full z-in-front-3 cursor-pointer hover:bg-blue-500 hover:text-white'
					}
				/>
			)}

			<ExpansionInput
				label={props.label ?? 'Experience'}
				value={props.searchTerm}
				onInput={onInput}
				onBlur={onBlur}
				onFocus={onFocus}
				width={(props.width ?? props.fullWidth) ? '100%' : '230px'}
				elementToFocus={props.elementToFocus}
				suggestionLimit={inputLimit}
				suggestions={matchingPlaces.map((place) => {
					return {
						value: (
							<div className={'flex items-center'}>
								<div className={'h-7 w-7 mr-2'}>
									<PlaceThumbnail
										place={place}
										size={PlaceThumbnailSize.VERY_SMALL}
										className={'h-7 w-7 min-h-7 min-w-7'}
									/>
								</div>

								<div className={'truncate pr-2'}>
									{place.name}
								</div>
							</div>
						),
						title: place.name,
						onClick: () => {
							setCurrentPlace(place);
							props.setSearchTerm(place.name);
						},
					};
				})}
				inputClassName={ClassString({
					static: '!h-9 !pr-8',
					dynamic: {
						'!pl-9 !rounded-l-md': !!props.place,
					},
				})}
			/>
		</div>
	);
});

export default PlaceExpansionInput;
