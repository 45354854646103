import { observer } from 'mobx-react-lite';
import { ButtonIcon } from '@components/core/button/button.tsx';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import ClassString from '@utils/class-string.ts';
import themeStore from '@store/theme-store.ts';
import Icon from '@components/core/icon/icon.tsx';

interface ExpansionButtonProps {
	title: string;
	icon?: ButtonIcon;
	subMenuButtons: ExpansionButtonSubMenuButton[];
	className?: string;
	toggleable?: boolean;
	width?: string;
}

interface ExpansionButtonSubMenuButton {
	title: string;
	onClick: () => unknown;
	active?: boolean;
}

const ExpansionButton = observer(function ExpansionButton(
	props: ExpansionButtonProps
) {
	const ref = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(
		props.width ?? `${ref.current?.offsetWidth}px`
	);
	const [widestRefWidth, setWidestRefWidth] = useState(0);

	useEffect(() => {
		setWidth(props.width ?? `${ref.current?.offsetWidth}px`);
	}, [props.width]);

	useEffect(() => {
		const element = ref.current;

		if (!props.width && element) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					const newWidth = Math.round(entry.contentRect.width);
					if (newWidth > widestRefWidth) {
						setWidestRefWidth(newWidth);
						setWidth(`${newWidth}px`);
					}
				}
			});

			resizeObserver.observe(element);

			return () => {
				resizeObserver.unobserve(element);
			};
		}
	}, [props.width, widestRefWidth]);

	return (
		<div
			className={ClassString({
				static: 'relative h-button group',
				dynamic: {
					'hover:underline': themeStore.darkTheme,
				},
				custom: props.className,
			})}
			style={
				{
					'--button-count': props.subMenuButtons.length,
					width: width,
				} as CSSProperties
			}
		>
			<div
				className={ClassString({
					static: 'absolute w-full h-10 group-hover:h-expansion-button-open top-0 z-in-front overflow-hidden rounded-button transition-all ease-transition-curve bg-white dark:bg-gray-525 shadow-md border border-gray-600 dark:border-gray-500',
					dynamic: {
						'group-hover:overflow-y-auto group-hover:rounded-r-none':
							props.subMenuButtons.length > 10,
						'w-fit': !props.width,
					},
				})}
				ref={ref}
			>
				<div
					className={
						'sticky top-0 px-6 py-2 z-in-front-2 h-button whitespace-nowrap flex justify-center items-center group-hover:underline bg-white dark:bg-gray-525 dark:text-white hover:bg-blue-500 hover:text-white cursor-default group-hover:border-b border-gray-600 dark:border-gray-500'
					}
				>
					{props.title}
					{!!props.icon && (
						<Icon
							icon={props.icon.icon}
							size={props.icon.size ?? '18px'}
							className={'pl-2'}
						/>
					)}
				</div>

				{props.subMenuButtons.map((button) => {
					return (
						<div
							onClick={button.onClick}
							key={`${button.title}-${button.active}`}
							className={ClassString({
								static: 'flex justify-center items-center h-button text-gray-600 dark:text-white hover:bg-blue-500 hover:text-white cursor-pointer',
								dynamic: {
									'bg-white dark:bg-gray-525': !button.active,
									'underline text-white bg-blue-500 dark:bg-blue-500':
										button.active,
									'justify-start px-4': props.toggleable,
								},
							})}
							title={button.title}
						>
							<div className={'truncate'}>{button.title}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
});

export default ExpansionButton;
